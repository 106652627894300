<template>
  <div>
    <div class="d-flex mb-1 justify-space-between">
      <span>{{ label }}</span>
      <v-menu
        open-on-hover
        offset-y
        left
      >
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            class="text--disabled"
            size="20"
            color="primary"
            v-bind="attrs"
            v-on="on"
          >
            {{ icons.mdiHelpCircleOutline }}
          </v-icon>
        </template>
        <v-card
          max-width="300"
          class="pa-3 d-flex flex-column"
        >
          <span class="text-caption font-medium mb-2">Induk Hierarki</span>
          <span class="text-caption">{{ tooltipText }}</span>
        </v-card>
      </v-menu>
    </div>
    <v-autocomplete
      ref="hierarchyAutocomplete"
      v-model="inputData"
      :loading="isLoadingNonRecursive"
      :items="nonRecursiveData"
      outlined
      :disabled="isDisabled"
      item-value="id"
      item-text="name"
      dense
      hide-details="auto"
      clearable
      :rules="rules"
      placeholder="Pilih induk hierarki"
    >
      <template #no-data>
        <div
          class="px-4 py-2 text-subtitle-2 text--disabled text-center"
        >
          <p class="mb-0">
            Hierarki tidak ditemukan.
          </p>
          <p
            class="cursor-pointer mb-0 caption primary--text text-decoration-underline"
            @click="$refs.hierarchyAutocomplete.lazySearch = ''; fetchNonRecursiveData()"
          >
            Refresh
          </p>
        </div>
      </template>
      <template #item="data">
        {{ data.item.name }}
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import { computed, onMounted } from '@vue/composition-api'
import { mdiHelpCircleOutline } from '@mdi/js'
import useHierarchy from '@/composables/useHierarchy'

export default {
  props: {
    value: {
      type: [Object, Number],
      default: null,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: 'Induk Hierarki',
    },
    tooltipText: {
      type: String,
      default: 'Isi ini jika hierarki yang dibuat merupakan bawahan dari hierarki lain.',
    },
    rules: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const inputData = computed({
      get() {
        return props.value
      },
      set(val) {
        return emit('input', val)
      },
    })

    const { nonRecursiveData, isLoadingNonRecursive, fetchNonRecursiveData } = useHierarchy()

    onMounted(() => {
      fetchNonRecursiveData()
    })

    return {
      inputData,
      nonRecursiveData,
      isLoadingNonRecursive,
      fetchNonRecursiveData,
      icons: {
        mdiHelpCircleOutline,
      },
    }
  },
}
</script>

<style>

</style>
