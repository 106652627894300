import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSlideXReverseTransition } from 'vuetify/lib/components/transitions';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VHover,{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c(VCard,{staticClass:"pa-2 mb-2 d-flex align-center",attrs:{"outlined":""}},[_c(VAvatar,{staticClass:"v-avatar-light-bg mr-2",attrs:{"size":"32"}},[_c('span',[_vm._v(_vm._s(_vm.avatarText(_vm.data.name)))])]),_c('div',{staticClass:"d-flex flex-column"},[_c('router-link',{attrs:{"to":{ name: 'user-detail', params: { id: _vm.data.id } }}},[_c('span',{staticClass:"primary--text text-caption font-medium"},[_vm._v(_vm._s(_vm.data.name))])]),_c('small',{staticClass:"text--disabled text-caption"},[_vm._v(_vm._s(_vm.data.email))])],1),_c(VMenu,{attrs:{"bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VSlideXReverseTransition,{attrs:{"mode":"out-in"}},[_c(VIcon,_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(hover),expression:"hover"}],staticClass:"ml-auto"},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiDotsVertical)+" ")])],1)]}}],null,true)},[_c(VList,[_c(VListItem,{on:{"click":function($event){return _vm.$emit('move', _vm.data)}}},[_vm._v(" Pindah Ke Hierarki Lain ")])],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }