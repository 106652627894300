<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        lg="4"
        md="6"
      >
        <v-card class="pa-3 d-flex flex-column">
          <div class="d-flex w-full justify-space-between mr-2 align-start">
            <h3>Hierarki</h3>
            <div>
              <v-icon
                size="26"
                class="mr-2"
                color="primary"
                @click="fetchHierarchyTree()"
              >
                {{ icons.mdiRefresh }}
              </v-icon>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    size="28"
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                    @click="$refs.hierarchyForm.show()"
                  >
                    {{ icons.mdiPlus }}
                  </v-icon>
                </template>
                <span>Buat Hierarki Baru</span>
              </v-tooltip>
            </div>
          </div>
          <span class="d-block text-subtitle-2 mt-1">Buat hierarki untuk manajemen tim yang lebih baik.</span>
          <v-treeview
            :value="selectedTree"
            open-on-click
            item-key="id"
            :items="recursiveData"
            class="mt-4"
            open-all
            selectable
            selection-type="independent"
            return-object
            @input="handleTreeviewInput"
          >
            <template #append="{ item, open }">
              <XyzTransition
                appear
                xyz="fade down-2 out-up-2"
              >
                <div
                  v-if="open"
                >
                  <v-tooltip
                    bottom
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                        @click.stop="$refs.hierarchyForm.show(item)"
                      >
                        {{ icons.mdiPlus }}
                      </v-icon>
                    </template>
                    <span>Buat Hierarki Baru Disini</span>
                  </v-tooltip>
                </div>
              </XyzTransition>
            </template>
          </v-treeview>
        </v-card>
        <XyzTransition
          appear
          xyz="fade left-3 delay-5 out-delay-0"
        >
          <v-card
            v-if="selectedTree.length"
            class="pa-3 mt-6"
          >
            <div class="d-flex justify-space-between mb-3">
              <h4>Anggota</h4>
              <v-btn
                color="primary"
                x-small
                @click="$refs.hierarchyUserForm.show(selectedTree[0])"
              >
                Tambah
              </v-btn>
            </div>
            <div class="list">
              <HierarchyMember
                v-for="data in selectedTree[0].users"
                :key="data.id"
                :data="data"
                @move="$refs.hierarchyMoveParent.show(data)"
              />
            </div>
          </v-card>
        </XyzTransition>
      </v-col>
      <v-col
        cols="12"
        lg="8"
        md="6"
      >
        <XyzTransition
          appear
          xyz="fade right-4 delay-8 out-delay-0"
        >
          <div
            v-if="selectedTree.length"
          >
            <v-card class="pa-3">
              <div class="d-flex">
                <h3>{{ selectedTree[0].name }}</h3>
                <v-btn
                  class="ml-auto mr-2"
                  color="warning"
                  small
                  outlined
                  @click="$refs.hierarchyForm.update(selectedTree[0])"
                >
                  Ubah
                </v-btn>
                <v-btn
                  color="error"
                  small
                  outlined
                  @click="deleteHierarchyData"
                >
                  Hapus
                </v-btn>
              </div>
              <p>{{ selectedTree[0].description ? selectedTree[0].description : "Tidak ada deskripsi" }}</p>
            </v-card>
          </div>
        </XyzTransition>
      </v-col>
    </v-row>

    <HierarchyForm
      ref="hierarchyForm"
      @success="handleUpdate()"
    />

    <HierarchyUserForm
      ref="hierarchyUserForm"
      @success="handleUpdate()"
    />

    <HierarchyMoveParent
      ref="hierarchyMoveParent"
      @success="handleUpdate()"
    />
  </div>
</template>

<script>
import {
  computed, onMounted, ref, watch,
} from '@vue/composition-api'
import {
  mdiPlus,
  mdiRefresh,
} from '@mdi/js'
import { useDebounceFn } from '@vueuse/core'
import router from '@/router'
import HierarchyForm from './HierarchyForm.vue'
import useHierarchy from '@/composables/useHierarchy'
import HierarchyMember from './components/HierarchyMember.vue'
import HierarchyUserForm from './components/HierarchyUserForm.vue'
import HierarchyMoveParent from './components/HierarchyMoveParent.vue'

export default {
  components: {
    HierarchyForm,
    HierarchyMember,
    HierarchyUserForm,
    HierarchyMoveParent,
  },
  setup() {
    const selectedTree = ref([])

    const handleTreeviewInput = useDebounceFn(data => {
      if (data.length) {
        selectedTree.value = [data[data.length - 1]]

        if (router.currentRoute.params.id !== selectedTree.value[0].id) {
          router.replace({ name: 'hierarchy-detail', params: { id: selectedTree.value[0].id } }).catch(() => {})
        }
      } else {
        selectedTree.value = []
        router.replace({ name: 'hierarchy' })
      }
    }, 100)
    const {
      recursiveData, nonRecursiveData, fetchHierarchyTree, deleteHierarchyFunc, fetchNonRecursiveData,
    } = useHierarchy()
    const treeItems = computed(() => recursiveData.value)

    const newHierarchy = item => {
      console.log('add new hierarchy', item)
    }
    const deleteHierarchyData = async () => {
      deleteHierarchyFunc({ formData: { id: selectedTree.value[0].id } }, () => {
        selectedTree.value = []
        fetchHierarchyTree()
      })
    }
    watch(recursiveData, () => {

    })
    const handleUpdate = () => {
      fetchHierarchyTree(() => {
        if (selectedTree.value.length) {
          fetchNonRecursiveData(() => {
            const insertData = nonRecursiveData.value.find(data => data.id === selectedTree.value[0].id)
            console.log(insertData)
            selectedTree.value = [insertData]
          })
        }
      })
    }
    onMounted(() => {
      fetchHierarchyTree()
    })

    return {
      selectedTree,
      treeItems,
      handleUpdate,
      handleTreeviewInput,
      newHierarchy,
      deleteHierarchyFunc,
      recursiveData,
      fetchHierarchyTree,
      deleteHierarchyData,
      icons: {
        mdiPlus,
        mdiRefresh,
      },
    }
  },
}
</script>

<style>

</style>
