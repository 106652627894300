import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"600"},model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}},[_c(VCard,{staticClass:"pa-4 d-flex flex-column"},[_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"text-subtitle-1 font-medium primary--text"},[_vm._v("Tambah Anggota")]),_c(VIcon,{staticClass:"ml-auto",on:{"click":_vm.close}},[_vm._v(" "+_vm._s(_vm.icons.mdiClose)+" ")])],1),_c('span',{staticClass:"text-subtitle-2"},[_vm._v(_vm._s(_vm.hierarchy.name))]),_c(VDivider,{staticClass:"my-4"}),_c(VForm,{ref:"userForm",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c('user-auto-suggest',{attrs:{"label":"Pilih anggota","multiple":"","max":10,"min":1,"rules":[_vm.required(_vm.userId)]},model:{value:(_vm.userId),callback:function ($$v) {_vm.userId=$$v},expression:"userId"}})],1),_c(VBtn,{staticClass:"ml-auto mt-4",attrs:{"loading":_vm.isLoadingForm,"color":"primary"},on:{"click":_vm.submitForm}},[_vm._v(" Tambah ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }