<template>
  <v-dialog
    v-model="isOpen"
    max-width="600px"
    @input="!$event ? $emit('close') : null"
  >
    <v-card>
      <div class="d-flex justify-space-between pa-4">
        <span class="text-h6 font-medium primary--text">Hierarki Baru</span>
        <v-icon
          @click="close"
        >
          {{ icons.mdiClose }}
        </v-icon>
      </div>
      <v-card-text class="pt-2">
        <v-form
          ref="hierarchyForm"
          lazy-validation
          @submit.prevent="submitForm"
        >
          <v-text-field
            v-model="formData.name"
            :rules="[required, maxLength(formData.name, 50)]"
            label="Nama Hierarki (wajib diisi)"
            placeholder="Input nama hierarki"
            dense
            outlined
            counter="50"
          />
          <v-textarea
            v-model="formData.description"
            :rules="[maxLength(formData.description, 200)]"
            label="Deskripsi"
            placeholder="Isi deskripsi hierarki"
            outlined
            dense
            counter="200"
          />
          <user-auto-suggest
            v-model="formData.users"
            label="Pilih anggota hierarki"
            multiple
            :max="20"
            :min="2"
            :rules="[required(formData.userId)]"
          />
          <v-divider class="mt-6 mb-4" />
          <hierarchy-parent
            v-model="formData.parent_id"
            :is-disabled="isParentDisabled"
          />
        </v-form>
        <div class="d-flex justify-end mt-4">
          <v-btn
            class="mr-2"
            outlined
            @click="clearForm"
          >
            Reset Form
          </v-btn>
          <v-btn
            color="primary"
            :loading="isLoadingForm"
            @click="submitForm"
          >
            Simpan Hierarki
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref } from '@vue/composition-api'
import { mdiClose } from '@mdi/js'
import { required, maxLength } from '@core/utils/validation'
import UserAutoSuggest from '@/components/inputs/UserAutoSuggest.vue'
import HierarchyParent from './components/HierarchyParent.vue'
import useHierarchy from '@/composables/useHierarchy'

export default {
  components: {
    UserAutoSuggest,
    HierarchyParent,
  },
  setup(props, { emit }) {
    const isOpen = ref(false)
    const isUpdate = ref(false)
    const isParentDisabled = ref(false)
    const hierarchyForm = ref()
    const formData = ref({
      id: null,
      name: '',
      description: '',
      users: [],
      parent_id: null,
    })
    const loadingSubmit = ref(false)
    const { isLoadingForm, updateHierarchyFunc, addHierarchyFunc } = useHierarchy()
    const show = parent => {
      isOpen.value = true

      if (parent) {
        isParentDisabled.value = true
        formData.value = { ...formData.value, parent_id: parent.id, users: formData.value.users.map(value => value.id) }
      } else {
        isParentDisabled.value = false
        formData.value = { ...formData.value, parent_id: null, users: formData.value.users.map(value => value.id) }
      }
    }

    const update = hierarchyData => {
      isUpdate.value = true
      formData.value = { ...hierarchyData, userId: hierarchyData.users.map(data => data.id) }
      isOpen.value = true
    }

    const close = () => {
      isOpen.value = false
    }
    const clearForm = () => {
      formData.value = {
        id: null,
        name: '',
        description: '',
        userId: [],
        parent_id: null,
      }
    }
    const submitForm = () => {
      if (hierarchyForm.value.validate()) {
        if (isUpdate.value) {
          updateHierarchyFunc({ formData: formData.value }, () => {
            emit('success')
            close()
            clearForm()
          })
        } else {
          addHierarchyFunc({ formData: formData.value }, () => {
            emit('success')
            close()
            clearForm()
          })
        }
      }
    }

    return {
      isOpen,
      show,
      close,
      formData,
      hierarchyForm,
      loadingSubmit,
      submitForm,
      update,
      isLoadingForm,
      isParentDisabled,
      clearForm,

      // form validation
      required,
      maxLength,

      icons: {
        mdiClose,
      },
    }
  },
}
</script>

<style>

</style>
