<template>
  <v-dialog
    v-model="isOpen"
    max-width="600"
  >
    <v-card
      class="pa-4 d-flex flex-column"
    >
      <div class="d-flex align-center">
        <span class="text-subtitle-1 font-medium primary--text">Pindah ke Hierarki Lain</span>
        <v-icon
          class="ml-auto"
          @click="close"
        >
          {{ icons.mdiClose }}
        </v-icon>
      </div>
      <span class="text-subtitle-2">{{ user.name }}</span>
      <v-divider class="my-4" />
      <v-form
        ref="moveParentForm"
        lazy-validation
        @submit.prevent="submitForm"
      >
        <HierarchyParent
          v-model="newParent"
          label="Induk Hierarki Baru"
          tooltip-text="Pilih induk hierarki baru dari anggota yang ingin dipindah"
          :rules="[required]"
        />
      </v-form>
      <v-btn
        class="ml-auto mt-4"
        color="primary"
        :loading="isLoadingForm"
        @click="submitForm"
      >
        Tambah
      </v-btn>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref } from '@vue/composition-api'
import { mdiClose } from '@mdi/js'
import { required } from '@core/utils/validation'
import HierarchyParent from './HierarchyParent.vue'
import useHierarchy from '@/composables/useHierarchy'

export default {
  components: {
    HierarchyParent,
  },
  setup(props, { emit }) {
    const moveParentForm = ref()
    const user = ref({})
    const isOpen = ref(false)
    const newParent = ref()
    const { isLoadingForm, addUserHierarchyFunc } = useHierarchy()
    const show = data => {
      console.log(data)
      user.value = data
      isOpen.value = true
    }

    const close = () => {
      isOpen.value = false
      newParent.value = null
    }

    const submitForm = () => {
      console.log('submit!')
      console.log(newParent.value)
      if (moveParentForm.value.validate()) {
        addUserHierarchyFunc({ user_id: user.value.id, hierarchy_id: +newParent.value }, () => {
          close()
          emit('success')
        })
      }
    }

    return {
      moveParentForm,
      newParent,
      user,
      isOpen,
      isLoadingForm,

      show,
      close,
      submitForm,

      icons: {
        mdiClose,
      },

      required,
    }
  },
}
</script>

<style>

</style>
