<template>
  <v-hover #default="{ hover }">
    <v-card
      class="pa-2 mb-2 d-flex align-center"
      outlined
    >
      <v-avatar
        size="32"
        class="v-avatar-light-bg mr-2"
      >
        <span>{{ avatarText(data.name) }}</span>
      </v-avatar>
      <div class="d-flex flex-column">
        <router-link :to="{ name: 'user-detail', params: { id: data.id } }">
          <span class="primary--text text-caption font-medium">{{ data.name }}</span>
        </router-link>
        <small class="text--disabled text-caption">{{ data.email }}</small>
      </div>
      <v-menu
        bottom
        offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <v-slide-x-reverse-transition mode="out-in">
            <v-icon
              v-show="hover"
              v-bind="attrs"
              class="ml-auto"
              v-on="on"
            >
              {{ icons.mdiDotsVertical }}
            </v-icon>
          </v-slide-x-reverse-transition>
        </template>
        <v-list>
          <v-list-item
            @click="$emit('move', data)"
          >
            Pindah Ke Hierarki Lain
          </v-list-item>
        </v-list>
      </v-menu>
    </v-card>
  </v-hover>
</template>

<script>
import { avatarText } from '@core/utils/filter'
import { mdiDotsVertical } from '@mdi/js'

export default {
  props: {
    data: {
      type: Object,
      default: null,
      required: true,
    },
  },
  setup() {
    return {
      avatarText,

      icons: {
        mdiDotsVertical,
      },
    }
  },
}
</script>

<style>

</style>
