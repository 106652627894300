<template>
  <v-dialog
    v-model="isOpen"
    max-width="600"
  >
    <v-card
      class="pa-4 d-flex flex-column"
    >
      <div class="d-flex align-center">
        <span class="text-subtitle-1 font-medium primary--text">Tambah Anggota</span>
        <v-icon
          class="ml-auto"
          @click="close"
        >
          {{ icons.mdiClose }}
        </v-icon>
      </div>
      <span class="text-subtitle-2">{{ hierarchy.name }}</span>
      <v-divider class="my-4" />
      <v-form
        ref="userForm"
        lazy-validation
        @submit.prevent="submitForm"
      >
        <user-auto-suggest
          v-model="userId"
          label="Pilih anggota"
          multiple
          :max="10"
          :min="1"
          :rules="[required(userId)]"
        />
      </v-form>
      <v-btn
        :loading="isLoadingForm"
        class="ml-auto mt-4"
        color="primary"
        @click="submitForm"
      >
        Tambah
      </v-btn>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref } from '@vue/composition-api'
import { mdiClose } from '@mdi/js'
import { required } from '@core/utils/validation'
import UserAutoSuggest from '@/components/inputs/UserAutoSuggest.vue'
import useHierarchy from '@/composables/useHierarchy'

export default {
  components: {
    UserAutoSuggest,
  },
  setup(props, { emit }) {
    const userForm = ref()
    const hierarchy = ref({})
    const userId = ref([])
    const isOpen = ref(false)
    const { isLoadingForm, addUserHierarchyFunc } = useHierarchy()
    const show = data => {
      hierarchy.value = data
      isOpen.value = true
    }

    const close = () => {
      isOpen.value = false
      userId.value = []
    }

    const submitForm = () => {
      if (userForm.value.validate()) {
        addUserHierarchyFunc({ user_id: userId.value.map(value => value.id), hierarchy_id: hierarchy.value.id }, () => {
          close()
          emit('success')
        })
      }
    }

    return {
      userForm,
      hierarchy,
      isOpen,
      userId,
      isLoadingForm,

      show,
      close,
      submitForm,

      icons: {
        mdiClose,
      },

      required,
    }
  },
}
</script>

<style>

</style>
